<template>
  <span class="starWrapper">
    <i
      v-for="i in total"
      :key="i"
      class="fa fa-star"
      :class="{ 'text-muted': i > starValue }"
    ></i>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    total: {
      type: Number,
      default: 5,
    },
    content: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    starValue() {
      if (this.value !== null) return this.value;
      if (!this.content.length) return "0";
			return this.content.reduce((total, record) => total + record.rating, 0) / this.content.length;
    },
  },
};
</script>
